import React from "react";
import { graphql, StaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import Bio from "../components/bio"
import SlideShow from "../components/slideShow";

import "../style/normalize.css";
import "../style/all.scss";
import DACS1 from "../assets/images/dacs01.jpg"
import DACS2 from "../assets/images/dacs02.jpg"
import DACS3 from "../assets/images/dacs03.jpg"
import DACS4 from "../assets/images/dacs04.jpg"
import DACS5 from "../assets/images/dacs05.jpg"
import DACS6 from "../assets/images/dacs06.jpg"
import DACS7 from "../assets/images/dacs07.jpg"
import DACS8 from "../assets/images/dacs08.jpg"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template

var settings = {
  images: [
    { url: DACS1, position: "center" },
    { url: DACS2, position: "center" },
    { url: DACS3, position: "center" },
    { url: DACS4, position: "center" },
    { url: DACS5, position: "center" },
    { url: DACS6, position: "center" },
    { url: DACS7, position: "center" },
    { url: DACS8, position: "center" }
  ],
  // Delay.
  delay: 8000,
};

const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;
  let postCounter = 0;

  return (
    <Layout title={siteTitle}>
      <SEO title="DACS" keywords={[`devlog`, `blog`, `gatsby`, `javascript`, `react`]} />
      {/* <Bio /> */}
      {data.site.siteMetadata.description && (
        <React.Fragment>
          <SlideShow settings={settings} />
          <header className="page-head">
            <h2 className="page-head-title">{data.site.siteMetadata.description}</h2>
          </header>
        </React.Fragment>
      )}
    </Layout>
  );
};

const indexQuery = graphql`{
  site {
    siteMetadata {
      title
      description
    }
  }
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          description
          tags
          thumbnail {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => <BlogIndex location={props.location} props data={data} {...props} />}
  />
);
